
    <div class="border-end" id="sidebar-wrapper">
        <div class="sidebar-heading border-bottom">
            <a [routerLink]="['/dashboard', 'home']">
                <div class="logo-container mb-3">

                    <img class="sideNavImg mb-2"  src="../assets/imgs/cubes-logo.png" alt="" >
                </div>
                    <h5>Nuqta</h5>
                    <h6 class="side-user-name">Welcome  {{username}} </h6>
            </a>
        </div>
        <!-- <div class="side-User">
            <a class="sideNavUser" href="#">
                <img class="sideNavUserImg" src="https://via.placeholder.com/40x40" alt="" width="40" height="40">
                <p class="sideNavUserName">Shorouk Shaheen</p>
            </a>
        </div> -->
        <div class="list-group list-group-flush">
            <a class="list-group-item list-group-item-action p-3" [routerLink]="['/dashboard', 'home']">
                <i class="fas fa-home"></i>
                الصفحة الرئيسية</a>
            <a class="list-group-item list-group-item-action p-3" [routerLink]="['/dashboard', 'companies']">
                <i class="fas fa-building"></i>
                الشركات</a>
            <a class="list-group-item list-group-item-action p-3" [routerLink]="['/dashboard', 'products']">
                <i class="fas fa-box-open"></i>
                المنتجات</a>
            <!-- <a class="list-group-item list-group-item-action p-3" [routerLink]="['/dashboard', 'users']">
                <i class="fas fa-users"></i>
                المستخدمين</a> -->
        </div>
    </div>


