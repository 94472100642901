import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  username: string;
  token: string;

  constructor() { }

  ngOnInit(): void {
    this.username =  localStorage.getItem("user_name");
   this.token =  localStorage.getItem("id_token");
  }

}
