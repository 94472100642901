<div class="d-flex" id="wrapper">
    <!-- Sidebar-->
    <app-side-bar></app-side-bar>

    <!-- Page content wrapper-->
    <div id="page-content-wrapper">

        <!-- Top navigation-->
        <app-navbar></app-navbar>
        <!-- Page content-->
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>