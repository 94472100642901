<nav class="navbar navbar-expand-lg border-bottom">
    <div class="container-fluid">
        <button class="btn btn-primary" id="sidebarToggle"><i class="fas fa-bars"></i></button>
      <!--  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars"></i>
        </button> -->
        <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent"> -->
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                <li class="nav-item active"><a class="nav-link btn btn-old-web" href="http://cubes.ly/">الموقع السابق</a></li>
                <li class="nav-item"><a class="nav-link btn btn-log-out" [routerLink]="['/auth', 'login']">تسجيل الخروج</a></li>
            </ul>
        <!-- </div> -->
    </div>
</nav>
