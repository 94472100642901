import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { UserLayoutComponent } from './core/user-layout/user-layout.component';


const routes: Routes = [
  {
    path: "dashboard",
    component: UserLayoutComponent,
    children: [
      {
        path: "home",
        loadChildren: () => import("./views/home/home.module").then(
          (m) => m.HomeModule
        )
      },
      {
        path: "companies",
        loadChildren: () => import("./views/companies/companies.module").then(
          (m) => m.CompaniesModule
        ),        
      },
      {
        path: "products",
        loadChildren: () => import("./views/products/products.module").then(
          (m) => m.ProductsModule
        ),        
      },
      // {
      //   path: "users",
      //   loadChildren: () => import("./views/users/users.module").then(
      //     (m) => m.UsersModule
      //   ),        
      // },
      {
        path: "",
        redirectTo : "home",
        pathMatch: "prefix"
      }
    ]
  },
  {
    path: "auth",
    loadChildren: () => import("./core/auth/auth.module").then((m) => m.AuthModule)
  },
  {
    path: "**",
    redirectTo : "auth",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  
  ]
})
export class AppRoutingModule { }
